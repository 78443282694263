import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Sadly, rideMinnesota is no more. The content you are looking for probably doesn't exist. Please read more on the <Link to="/">homepage</Link>.</p>
  </Layout>
)

export default NotFoundPage
